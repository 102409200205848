@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-opacity.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-border-radius.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-car-rental-summary-form {
  .t-no-wrap {
    white-space: nowrap;
  }

  @include mobile {
    .t-car-detail {
      display: block;
      padding-left: 15%;
    }
  }

  .t-banner-cancellation {
    background-color: #ECFDF5;
    border-radius: 8px;
    border: 1px solid #10B981;
  }

  .t-date-picker {
    .t-input-global-datepicker {
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
    }

    /deep/ {
      .el-input__inner {
        border: 0;
        border-radius: 0;
        line-height: 38px;
        padding-right: 0;
        padding-left: 0;
        height: 100%;
        background-color: $color-grey-lightest;

        @include placeholder {
          color: $color-grey-dark;
          font-size: 14px;
        }
      }

      .el-input__suffix {
        & .el-input__icon {
          opacity: 0;
          visibility: hidden;

          &.el-icon-circle-close {
            top: 6px !important;
            opacity: 1;
            visibility: visible;

            @include transform(translateY(0) !important);
            @include transition($primary-transition);
          }
        }
      }

      .el-icon-date {
        color: $color-grey-dark !important;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 20px !important;
        height: auto;

        &::before {
          content: '\ea3e';
        }
      }

      .el-icon-circle-close {
        color: $color-grey-dark !important;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 20px !important;
        height: auto;
        right: 0;

        &::before {
          content: '\ea53';

          @include icon-default;
        }
      }

      [class^="el-icon-"],
      [class*=" el-icon-"] {
        @include icon-default;
      }
    }
  }

  .mileage-container {
    position: relative;
    display: inline-block;
  }

  .tooltip {
    visibility: hidden;
    background-color: #374044;
    color: white;
    padding: 12px;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.3s;
    width: max-content;
  }

  .t-car-limited-mileage {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
  }

  .mileage-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
    width: 250px;
    padding: 12px;
    overflow: visible;
    white-space: normal;
  }
}

@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";

.trv-header {
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;

  .t-display-inline-flex {
    display: inline-flex;
  }

  .t-navbar-top-main {
    justify-content: space-between;
    width: 100%;
  }

  @include mobile {
    #modalHeaderSearchSuggestion {
      padding: 0 32px !important;
    }
  }

  & #targetSearchSuggestion {
    @include mobile {
      & #cloneSearchSuggestion {
        display: block !important;

        & .t-navbar-search-dropdown {
          position: relative;
          pointer-events: fill;
          opacity: 1;
          visibility: visible;

          @include no-box-shadow;
        }
      }
    }

    @include tablet {
      & #cloneSearchSuggestion {
        display: block !important;

        & .t-navbar-search-dropdown {
          position: relative;
          pointer-events: fill;
          opacity: 1;
          visibility: visible;

          @include no-box-shadow;
        }
      }
    }
  }
}

.travlr-ui {
  .t-navbar-top-left,
  .t-navbar-top-right {
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  .t-navbar-top-right-menus-list-item-link {
    padding: 20px 8px;
  }

  .t-navbar-with-scroll {
    flex: 1 1 0%;

    .list-item-parent-a {
      white-space: nowrap;
      display: flex;

      & .nav-item-text {
        line-height: $line-height-text-5;
      }

      & .nav-item-text + .t-badge {
        margin-left: 4px;
      }
    }

    & > ul {
      flex-wrap: wrap;

      & > li {
        &:hover {
          & > a {
            &::after {
              width: 100%;
              left: 0;
            }
          }
        }

        & > a {
          padding: 24px 16px;

          &::after {
            position: absolute;
            bottom: 0;
            content: '';
            height: 4px;
            width: 0;

            @include transition($primary-transition);
            background-color: $color-primary;
            right: 0;
          }
        }
      }
    }

    & .first-level-menu {
      height: auto !important;
      max-height: 422px;
    }

    & .second-level-menu {
      height: auto !important;
      max-height: 380px;
    }

    & .third-level-menu {
      height: auto !important;
      max-height: 340px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    & .list-item-parent-a,
    & .list-item-child-a {
      & .t-badge {
        max-width: 96px;
        white-space: nowrap;
        justify-content: flex-start;
        line-height: 0.5rem;
        height: 14px;
        width: fit-content;
        padding: 0 5px;
        top: -8px;

        & .t-badge-clamped-text {
          line-height: 0.5em;
          font-weight: $weight-semibold !important;
        }

        & .t-badge-hover {
          background-color: $color-primary;
          display: none;
          justify-content: start;
          top: 0;
          bottom: 0;
          left: 0;
          border-radius: 2px;
          padding: 0 5px;
          line-height: 0.5em;
          z-index: 10;
          font-weight: $weight-semibold !important;
        }

        &:hover .t-badge-hover {
          display: flex;
        }
      }
    }

    & .list-item-child-a {
      & .list-item-child-text {
        flex-direction: column;
      }

      & .t-badge {
        position: relative;
        width: fit-content;
        top: 0;
        left: 0;
      }
    }
  }

  .second-level-menu .list-item-child:hover .third-level {
    visibility: visible;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }

  .travlr-ui .t-navbar-with-scroll .third-level {
    top: 0;
    left: 100%;
  }

  .travlr-ui .t-navbar-with-scroll .dropdown-menu.third-level {
    top: 0;
    left: 100%;
  }

  .t-navbar-top-right-menus-list-item-child {
    top: 100%;
    right: 0;
    z-index: 2;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;

    & .t-user-account-dropdown-wrapper {
      margin-top: 2px;
      padding: 8px 0;
      overflow: hidden;
    }
  }

  .t-m-t-2 {
    margin-top: 2px;
  }

  .margin-dropdown-icon {
    margin-top: -2px;
  }

  .search-toggle-btn {
    background: none;
    border: none;
    cursor: pointer;
  }

  .search-dropdown-menu {
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid $color-grey-lighter;
    z-index: 1000;
    padding: 20px;
    margin-top: 2px;
    min-width: 320px;
  }
}
